<!--
 * @Author: your name
 * @Date: 2021-11-23 14:32:20
<<<<<<< HEAD
 * @LastEditTime: 2021-11-23 17:57:42
=======
 * @LastEditTime: 2021-11-23 17:53:16
>>>>>>> 2c946801ab91161c2793e860c5221fe84f59678c
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \NFT\nft-front\src\App.vue
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease-in;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.el-tooltip__popper.is-light {
  border-width: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.popper-text-class {
  width: 310px;
  border: 0;
}

.work-show-case {
  width: 263px;
  box-sizing: border-box;

}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 15px;
  /*滚动条宽度*/
  height: 15px;
  /*滚动条高度*/
  background-color: #e6eaee;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(202, 202, 202, 0.3);
  background-color: #bdc6ce;
  border-radius: 2em;
  /*滚动条的背景颜色*/
}
</style>
