import axios from 'axios'
import Url from "../../public/baseUrl";
import {getToken, removeToken} from "../utils/auth";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


export const commonGetBaseUrl = function getBaseUrl() {
    let baseUrl = "";
    switch (process.env.VUE_APP_CURENV) {
        case "development":
            baseUrl = Url.DEV_BASE_URL; //开发
            break;
        case "test":
            baseUrl = Url.TEST_BASE_URL; //测试
            break;
         case "production":
             baseUrl = Url.ONLINE_BASE_URL; //生产环境
             break;
    }
    return baseUrl
}

export const commonGetNginxBaseUrl = function getBaseUrl() {
    let baseUrl = "";
    switch (process.env.VUE_APP_CURENV) {
        case "development":
            baseUrl = Url.DEV_BASE_NGIGX; //开发
            break;
        case "test":
            baseUrl = Url.TEST_BASE_NGIGX; //测试
            break;
        /* case "production":
             baseUrl = "https://app-t.xxxxxxxxx.com"; //生产环境url
             break;*/
    }
    return baseUrl
}

const service = axios.create({
    baseURL: commonGetBaseUrl(),
    // baseURL: BASE_TEXT_URL,
    timeout: 10000
})

service.interceptors.request.use(
    config => {
        config.headers['token'] = getToken()
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use((response) => {
        if (response.data.code == 401) {
            removeToken()
            return response.data
        }
        return response.data

    },
    error => {
        return Promise.reject(error)
    }
)
export default service
