import 'font-awesome/css/font-awesome.css'
import '../public/iconfont/iconfont.css'

import 'element-ui/lib/theme-chalk/index.css';


import VueStar from 'vue-star'
import VueStarPlus from 'vue-star-plus'
import dayjs from "dayjs"


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';

import axios from "./http/index";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios).use(ElementUI)

Vue.prototype.dayjs = dayjs;


Vue.component('VueStar', VueStar)
Vue.component('vue-star-plus', VueStarPlus)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
