import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        UserInfo: {},
        token: '',
        isLogin: false,
        menuActive: 0,
    },
    getters: {
        getToken(state) {
            return state.token
        },
        getUserInfo(state) {
            return state.UserInfo
        },
        getIsLogin(state) {
            return state.isLogin
        }
    },
    mutations: {
        setUserInfo(state, value) {
            state.UserInfo = value
        },
        setToken(state, value) {
            state.token = value
        },
        setIsLogin(state, value) {
            state.isLogin = value
        },
        setMenuActive(state, value) {
            this.state.menuActive = value
        }
    },
    actions: {
        setActionToken({commit}, value) {
            commit('setToken', value)
        }
    },
    modules: {}
})
