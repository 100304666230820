//开发环境配置
export default {
   // DEV_BASE_URL : 'http://47.108.70.182:12001/nft-api/',
   DEV_BASE_URL : 'http://localhost:16001/nft-api/',
    //  DEV_BASE_URL : 'http://192.168.3.180:16001/nft-api/',
   //  DEV_BASE_URL : 'http://47.108.70.182:11001/',
    DEV_BASE_NGIGX : 'http://localhost:16001/nft-api/',
//测试环境配置
    TEST_BASE_URL : 'http://47.108.70.182:16001/nft-api/',
    TEST_BASE_NGIGX : 'http://192.168.1.37:8082',

    ONLINE_BASE_URL : 'https://www.metaversehub.net/nft-api',
    ONLINE_BASE_NGIGX : 'https://www.metaversehub.net/nft-api'
}
