import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'AppContainer',
        redirect: '/index',
        component: () => import('../components/layout/AppContainer'),
        children: [
            {
                path: '/index',
                name: "Home",
                component: () => import('../views/home/index'),
                meta: {title: '首页'}
            },
            {
                path: '/author-article-list/:auId',
                name: 'AuthorArticleList',
                component: () => import('../views/authorArticleList'),
                meta: {title: '作者文章列表'}
            },
            {
                path: '/article-detail/:uuid',
                name: 'ArticleDetail',
                component: () => import('../views/ArticleDetail'),
                meta: {title: '文章详情'}
            },
            {
                path: '/fast-list',
                name: 'FastList',
                component: () => import('../views/fastList'),
                meta: {title: '快讯文章列表'}
            },
            {
                path: '/fast-detail/:uuid',
                name: 'FastDetail',
                component: () => import('../views/fastDetail'),
                meta: {title: '快讯文章详情'}
            },
            {
                path: '/special-pre',
                name: 'SpecialPre',
                component: () => import('../views/special'),
                meta: {title: '专题'}
            },
            {
                path: '/special-list/:spId',
                name: 'SpecialList',
                component: () => import('../views/specialList'),
                meta: {title: '专题文章列表'}
            },
            {
                path: '/industry-nav',
                name: 'IndustryNav',
                component: () => import('../views/industryNav'),
                meta: {title: '行业导航列表'}
            },
            {
                path: '/works-list',
                name: 'WorksList',
                component: () => import('../views/worksList'),
                meta: {title: '作品列表'}
            },
            {
                path: '/about-us/:strCode',
                name: "AboutUS",
                component: () => import('../views/aboutUS'),
                meta: {title: '关于我们'}
            },
            {
                path: '/search-list/:searchVal',
                name: "SearchList",
                component: () => import('../views/searchList'),
                meta: {title: '搜索集合'}
            },
            {
                path: '/tag-list/:tagType',
                name: "TagList",
                component: () => import('../views/TagList'),
                meta: {title: '标签集合'}
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})
export default router
